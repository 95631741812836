import * as React from 'react';
import './App.css';
import { Header } from 'semantic-ui-react';
import SiteHeader from './SiteHeader';
import Scorecard from './Scorecard';
import Competition from './Competition';
import Survey from './Survey';
import SiteFooter from './Footer';
import Analytics from './Analytics';

class App extends React.Component {
  public render() {
    return (
      <div className="App">
        <SiteHeader />
        <div className="content">
          <div id="content_title">
            <Header as="h1">David A. Bowers Economic Forecast Luncheon</Header>
          </div>
          <div>
            <Header as="h2">Over Four Decades of Analysis and Forecasting</Header>
            <p>
              The David A. Bowers Economic Forecast Luncheon was created by David A. Bowers in 1974 to engage alumni in the school by sharing his perceptive predictions on the economy. The event, sponsored by the Alumni Association, quickly grew to a community-wide regional event. Bowers gave his last forecast in 2000 after 27 years of predictions, all while handling wild cards such as war, impeachment proceedings and government regulations. In recognition of his service the school renamed the luncheon in his honor, and the Alumni Association created the David A. Bowers Faculty Service Award, making Bowers its first recipient.
            </p>
          </div>
          <Scorecard />
          <Competition />
          <Survey />
          <Analytics />
          <SiteFooter />
        </div>
      </div>
    );
  }
}

export default App;
