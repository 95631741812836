import * as React from 'react';
import { Header, Table } from 'semantic-ui-react';
// import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import './scorecard.css';

interface ScorecardProps {
    
}

interface State {
}

class Scorecard extends React.Component<ScorecardProps, State> {
    constructor(props: ScorecardProps) {
        super(props);
        this.state = {
        };
    }

    public render() {
        const data = [
            [2.7, 2.0, 1.8, 2.2, "Change in Real GDP", 2.3, 2.5, 2.5],
            [5.7, 5.0, 4.7, 4.1, "Unemployment Rate", 4.5, 3.9, 3.9],
            [1.2, 0.4, 1.8, 2.1, "Change in CPI", 2.5, "NA", "NA"],
            [1.7, 2.0, 2.2, 2.1, "Change in CPI ex Food and Energy", 2.5, 2.3, 2.3],
            [0.10, 0.16, 0.45, 1.4, "Fed Funds Rate", 1.75, 2.2, 2.9],
            [2.28, 2.19, 2.13, 2.4, "10 yr. Treasury Rate", 3.5, 2.8, 3.0]
        ];
        return (
            <div id="scorecard">
                <Header as="h2">Official Scorecard</Header>
                <div className="tableHolder">
                    <Table celled={true} unstackable={true}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>2014 Actual</Table.HeaderCell>
                                <Table.HeaderCell>2015 Actual</Table.HeaderCell>
                                <Table.HeaderCell>2016 Actual</Table.HeaderCell>
                                <Table.HeaderCell>Current 2017 Estimate (as of 11/26/2017)</Table.HeaderCell>
                                <Table.HeaderCell>Macro Variable (%&Delta; 4Q/4Q or 4Table.HeaderCell Q)</Table.HeaderCell>
                                <Table.HeaderCell>Sniderman 2017 Forecast (in 2016)</Table.HeaderCell>
                                <Table.HeaderCell>Sniderman 2018 Forecast</Table.HeaderCell>
                                <Table.HeaderCell>Sniderman 2019 Forecast</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>{...data.map((row, idx) =>
                            <Table.Row key={idx}>
                                {...row.map((value, idx2) => <Table.Cell key={idx2}>
                                    {(typeof value === 'number') ? value.toFixed(2) : value}
                                </Table.Cell>)}
                            </Table.Row>
                        )}</Table.Body>
                    </Table>
                </div>
            </div>
        );
    };
}

export default Scorecard;