import * as React from 'react';
// import { Menu, Dropdown, Icon } from 'semantic-ui-react';
import './sitefooter.css';

import logo from './WeatherheadLogo.png';

interface SiteFooterProps {
    
}

interface State {
}

class SiteFooter extends React.Component<SiteFooterProps, State> {
    constructor(props: SiteFooterProps) {
        super(props);
        this.state = {
        };
    }

    public render() {
        return (
            <div className="App-footer">
                <div className="Footer-logo-holder">
                    <a href="//weatherhead.case.edu" target="_blank" rel="noopener noreferrer">
                        <img 
                            src={logo}
                            className="Footer-logo" 
                            alt="logo"
                        />
                    </a>
                </div>
                <div className="twd-link">
                    <span>Powered by <a href="//theowyesdavid.com" target="_blank">Theo. Wyes David, Ltd.</a></span>
                </div>
            </div>
        );
    };
}

export default SiteFooter;