import * as React from 'react';
import { Header, Form, Button, Input, Radio, TextArea } from 'semantic-ui-react';
import './survey.css';

interface SurveyProps {
    
}

interface State {
    satisfaction_lunch?: string;
    satisfaction_presenter?: string;
    'helpful_q&aSession'?: string;
    helpful_handout?: string;
    helpful_online?: string;
    experience?: string;
    name?: string;
}

class Survey extends React.Component<SurveyProps, State> {
    constructor(props: SurveyProps) {
        super(props);
        this.state = {
        };
    }

    private radioGen = (
        label: string,
        stateKey: string,
        values: string[], 
        onChangeGen: (stateKey: string) => (e: any, { value }: any) => void
    ) => <Form.Field inline={true}>
        <label className="">{label}</label>
        <div className="customRadioGroup">
            {...values.map(value => <Form.Field
                key={value}
                control={Radio}
                label={value}
                value={value}
                checked={this.state[stateKey] === value}
                onChange={onChangeGen(stateKey)}
            />)}
        </div>
    </Form.Field>;

    private onChangeGenRadio = (stateKey: string) => (e: any, { value }: any) => this.setState({ [stateKey]: value });

    public render() {
        const satisfactionValues = ["Very Satisfied", "Satisfied", "Neutral", "Dissatisfied", "Very Dissatisfied"];
        const helpfulValues = ["Very Much", "Somewhat", "Neutral", "Not At All"];
        return (
            <div id="survey">
                <Header as="h2">Luncheon Feedback Survey</Header>
                <p>
                    Thank you for attending the Bowers Economic Forecast Luncheon. Your feedback is important to us. Please take a moment to complete the short survey below and let us know your thoughts on this event.
                </p>
                <div style={{margin: '0 auto'}}>
                <Form>
                    {this.radioGen(
                        "Rate your overall level of satisfaction with the Bowers Luncheon.",
                        "satisfaction_lunch",
                        satisfactionValues,
                        this.onChangeGenRadio
                    )}
                    {this.radioGen(
                        "Rate your overall level of satisfaction with the presenter, Ellen Zentner.",
                        "satisfaction_presenter",
                        satisfactionValues,
                        this.onChangeGenRadio
                    )}
                    {this.radioGen(
                        "Was the Q&A session valuable/informative?",
                        "helpful_q&aSession",
                        helpfulValues,
                        this.onChangeGenRadio
                    )}
                    {this.radioGen(
                        "Was the program handout helpful?",
                        "helpful_handout",
                        helpfulValues,
                        this.onChangeGenRadio
                    )}
                    {this.radioGen(
                        "Is this online program convenient/useful?",
                        "helpful_online",
                        helpfulValues,
                        this.onChangeGenRadio
                    )}
                    <Form.Field control={TextArea} label='Tell us more about your experience today' placeholder='' />
                    <Form.Field control={Input} label='Name (optional)' placeholder='Name' />
                    <Form.Field control={Button} type='submit' color='blue'>Submit</Form.Field>
                </Form>
                </div>
            </div>
        );
    };
}

export default Survey;