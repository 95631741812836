import * as React from 'react';
import { Menu, Dropdown, Icon } from 'semantic-ui-react';
import './siteheader.css';

import logo from './BowersLogo.png';

const menuItems = ['scorecard', 'competition', 'survey', 'analytics'];

const onNextFrame = (callback: (...args: any[]) => void) => setTimeout(() => window.requestAnimationFrame(callback), 200);
const windowHash = () => {
    const decodedHash = decodeURIComponent(window.location.hash);
    const hashParts = decodedHash.split('#');
    return (hashParts.length > 1) ? hashParts.slice(-1)[0] : undefined;
}

interface SiteHeaderProps {
}

interface State {
    activeItem?: string;
}

class SiteHeader extends React.Component<SiteHeaderProps, State> {

    

    constructor(props: SiteHeaderProps) {
        super(props);
        this.state = {
        };
    }

    public componentDidMount() {
        const hash = windowHash();
        if (hash) {
            onNextFrame(() => this.scrollFn(hash))
        }
    }

    private scrollFn = (id: string) => { 
        if (id && menuItems.indexOf(id) !== -1) {
            const el = document.getElementById(id);
            if (el) {
                el.scrollIntoView(); 
                this.setState({ activeItem: id });
            }
        }
        return id; 
    };

    private handleItemClick = (event: any, attrs: any) => (attrs) ? this.scrollFn(attrs.name) : undefined

    public render() {
        const { activeItem } = this.state;

        return (
            <div className="App-header" style={{  }}>
                <div className="Header-logo-holder">
                    <a href="/#">
                        <img 
                            src={logo}
                            className="Header-logo" 
                            alt="logo"
                            onClick={this.handleItemClick as any}
                        />
                    </a>
                </div>
                <div className="Header-title">
                    David A. Bowers Economic Forecast Luncheon
                </div>
                <Dropdown id="Header-dropdown" item={true} icon={<Icon name='bars' size='big'/>} simple={true} direction={'left'}>
                    <Dropdown.Menu>
                        {...menuItems.map(item => <Dropdown.Item
                            as="a"
                            href={"/#"+item}
                            key={item}
                            name={item}
                            content={item[0].toLocaleUpperCase()+item.slice(1)}
                            // active={activeItem === item}
                            onClick={this.handleItemClick}
                        />)}
                    </Dropdown.Menu>
                </Dropdown>
                <Menu
                    id="Header-menu"
                    pointing={true} 
                    secondary={true}
                    style={{  
                        color: 'white'
                    }}
                >
                    {...menuItems.map(item => <Menu.Item
                        as="a"
                        href={"#"+item}
                        key={item}
                        name={item}
                        active={activeItem === item}
                        onClick={this.handleItemClick}
                    />)}
                </Menu>
            </div>
        );
    };
}

export default SiteHeader;