import * as React from 'react';
import { Header, Image } from 'semantic-ui-react';
// import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import './analytics.css';

interface AnalyticsProps {
    
}

const isMobile = () => navigator.userAgent.indexOf( "Mobile" ) !== -1 || 
navigator.userAgent.indexOf( "iPhone" ) !== -1 || 
navigator.userAgent.indexOf( "Android" ) !== -1 || 
navigator.userAgent.indexOf( "Windows Phone" ) !== -1;

interface State {
    isMobile: boolean;
}

class Analytics extends React.Component<AnalyticsProps, State> {
    constructor(props: AnalyticsProps) {
        super(props);
        
        this.state = {
            isMobile: isMobile()
        };
    }

    private fredEmbed = (title: string, graphId: string, picId: string) => <div className="chartsEmbedHolder">
        <Header as="h3">{title}</Header>
        {(!this.state.isMobile) ? <div className="fred-embed-container">
            <iframe 
                src={`https://fred.stlouisfed.org/graph/graph-landing.php?g=${graphId}&width=919&height=475`} // &width=670&height=475       mfTf
                scrolling="no" 
                frameBorder="0" 
                style={{overflow:'hidden'}}
                allowTransparency={true}
            />
        </div>
        : <div className="fred-image-container">
            <a target="_blank" href={`https://fred.stlouisfed.org/graph/?g=${graphId}`}>
                <Image src={`https://fred.stlouisfed.org/graph/fredgraph.png?g=${picId}`} />
                (Click on the graph to open interactive chart)
            </a>
        </div>}
    </div>;

    public render() {
        return (
            <div id="analytics">
                <Header as="h2">Analytics</Header>
                {this.fredEmbed('Federal Funds Rate', 'mfZ5', 'mfWC')}
                {this.fredEmbed('Real GDP Growth Rate', 'mfZd', 'mfYn')}
                <script src="https://fred.stlouisfed.org/graph/js/embed.js" type="text/javascript" />
            </div>
        );
    };
}

export default Analytics;