import * as React from 'react';
import { Header, Form, Checkbox, Button, Input, Radio, TextArea, Icon } from 'semantic-ui-react';
import './competition.css';

interface CompetitionProps {
    
}

interface State {
    wsomGrad?: boolean;
}

class Competition extends React.Component<CompetitionProps, State> {
    constructor(props: CompetitionProps) {
        super(props);
        this.state = {
        };
    }

    public handleWsomGrad = (e: any, { value }: any) => this.setState({ wsomGrad: (value === 'yes') });

    public render() {
        const { wsomGrad } = this.state;
        return (
            <div id="competition">
                <Header as="h2">2019 Weatherhead Forecaster Competition</Header>
                <Header as="h3">ENTRY FORM</Header>
                <Header as="h4">My 2019 Predictions are:</Header>
                <div style={{margin: '0 auto'}}>
                <Form>
                    <div className="percentsHolder">
                        <Form.Field>
                            <label className="requiredstar">Change in Real GDP</label>
                            <div className="percentInputHolder">
                                <Input required={true} type="number" min="-4" max="42" />
                                <Icon name='circle' size='tiny' style={{alignSelf: 'end'}}/>
                                <Input required={true} type="number" step="0.001" min="0" max="999" />
                                <Icon name='percent' />
                            </div>
                        </Form.Field>
                        <Form.Field>
                            <label className="requiredstar">Average Fed Funds Target Rate</label>
                            <div className="percentInputHolder">
                                <Input required={true} type="number" min="-4" max="42" />
                                <Icon name='circle' size='tiny' style={{alignSelf: 'end'}}/>
                                <Input required={true} type="number" step="0.001" min="0" max="999" />
                                <Icon name='percent' />
                            </div>
                        </Form.Field>
                    </div>
                    <Form.Group widths='equal'>
                        <Form.Field control={Input} required={true} label='Name' placeholder='Name' />
                        <Form.Field control={Input} label='Organization' placeholder='Organization' />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field control={Input} label='Phone' type="tel" placeholder='Phone' />
                        <Form.Field control={Input} required={true} label='Email' type="email" placeholder='Email' />
                    </Form.Group>
                    <Form.Field inline={true}>
                        <label className="">Case Western Reserve University Grad</label>
                        <div className="customRadioGroup">
                            <Form.Field
                                control={Radio}
                                label='Yes'
                                value='yes'
                                checked={wsomGrad === true}
                                onChange={this.handleWsomGrad}
                            />
                            <Form.Field
                                control={Radio}
                                label='No'
                                value='no'
                                checked={wsomGrad === false}
                                onChange={this.handleWsomGrad}
                            />
                        </div>
                    </Form.Field>
                    <Form.Group widths='equal' style={{display: (wsomGrad) ? 'flex' : 'none'}}>
                        <Form.Field control={Input} label='Year' type="number" min="1920" max="2018" />
                        <Form.Field control={Input} label='Degree' placeholder='' />
                    </Form.Group>
                    <Form.Field control={TextArea} label='Comments about the presentation' placeholder='' />
                    {undefined && <Form.Field control={Checkbox} label='I agree to the Terms and Conditions' required={true} />}
                    <Form.Field control={Button} type='submit'>Submit</Form.Field>
                </Form>
                </div>
            </div>
        );
    };
}

export default Competition;